<template>
    <div class="container-fluid">
        <headerDesign name="Creative Management"
                      logo="https://adretreaver.com/wp-content/uploads/2021/03/AdRlogo-500.png"
                      button-show="false"
                      button-name="Product  Configuration"
                      class="mb-4"></headerDesign>

        <div class="row p-2" style="background-color: #EBEBEB">
            <div class="col-4 col-md-4 p-3 d-flex justify-content-center">
                <a href="" to="/clients" @click="$router.push({ path: '/clients' })" class="font-weight-bolder text-decoration-none" style="font-size: 17px" v-text="'ALL CLIENTS'"/>
            </div>
            <div class="col-4 col-md-4 p-3 d-flex justify-content-center">
                <a href="" to="/clients" @click="$router.push({ path: '/clients', query: { edit: '1', id: plans.ClientId } })" class="font-weight-bolder text-decoration-none" style="font-size: 17px" v-text="'CLIENT MANAGEMENT'"/>
            </div>
            <div class="col-4 col-md-4 p-3 d-flex justify-content-center">
                <a href="" to="/clients" @click="$router.push({ path: '/product/management', query: {  client_id: plans.ClientId } })" class="font-weight-bolder text-decoration-none" style="font-size: 17px" v-text="'PRODUCT MANAGEMENT'"/>
            </div>
        </div>
        <div class="row border-bottom bg-white" v-if="domains">
            <div class="col-12 col-md-12" v-if="domains[0].client" >
                <p class="m-2 font-weight-bold">Client Name:<strong> {{domains[0].client.Name}}</strong></p>
            </div>
        </div>
        <div class="row mb-4 bg-white"  >
            <div class="col-4 col-md-4">
                <p class="m-2 font-weight-bold">Plan Type: <strong v-if="proxyInfo"> {{plans.PlanListing.Driven}}</strong></p>
            </div>
            <div class="col-4 col-md-4">
                <p class="m-2 font-weight-bold">Proxy Type: <strong v-if="proxyInfo"> {{plans.PlanListing.ProxyType}}</strong></p>
            </div>
            <div class="col-4 col-md-4">
                <p class="m-2 font-weight-bold" v-if="proxyInfo && plans.PlanListing.NumberOfClicks" >Plan Size: <strong>
                    {{plans.PlanListing.NumberOfClicks |convert}}</strong></p>
                <p v-else class="m-2 font-weight-bold">Plan Size: <strong v-if="proxyInfo && plans.PlanListing.Bandwidth ">
                    {{plans.PlanListing.Bandwidth |convert}}</strong></p>

            </div>
        </div >


        <div class="row mb-4 bg-white" >
            <b-table
                style="background-color: #ffffff"
                :items="item"
                :fields="field"
                stacked="md"
                show-empty
                @filtered="onFiltered"
                :select-mode="selectMode"
                responsive="sm"
                ref="selectableTable"
                selectable
                :busy="isBusy"
                @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)"
            >

                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>

                <template #row-details="row">
                    <b-row>
                        <b-col class="text-center">
                            <b-button class="text-center btn-dark text-white" size="sm" @click="onRowSelected(row.item, row.index)">
                                {{ row.detailsShowing ? 'Hide' : 'Edit' }}
                            </b-button>
                        </b-col>
                    </b-row>
                </template>

                <!-- A custom formatted column -->
                <template #cell(No_of_Geos)="data">
                    <b class="bg-primary text-white p-1">{{ data.item.No_of_Geos  }}</b>
                </template>

                <template #cell(Daily_Click_Distribution_Calculation)="data">
                    <b  v-if="data.item.Daily_Click_Distribution_Calculation">
                        {{ data.item.Daily_Click_Distribution_Calculation }}</b>
                </template>
                <template #empty="scope">

                    <b-row>
                        <b-col class="text-center">
                            <h6>There Is No Creative Please Create One by Selecting the Button Below</h6>
                            <b-button class="text-center btn-dark text-white" size="sm" @click="onRowSelected">
                                Add Creative
                            </b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-table>
        </div>


        <b-modal

            ref="modal"
            title="Modal Variants"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
            :footer-bg-variant="footerBgVariant"
            :footer-text-variant="footerTextVariant"
            no-close-on-backdrop
            size="xl"


        >
            <template #modal-header>
                <div class="w-100">
                    <div class="row">
                        <div class="col-md-1 col-sm-1 col-xs-12">
                            <b-img src="https://adretreaver.com/wp-content/uploads/2021/03/AdRlogo-500.png"
                                   class="w-50 ml-5 mt-3"></b-img>
                        </div>

                        <div class="col-md-6 col-sm-4 col-xs-12">
                            <h3 class="text-white mt-3">Email configuration</h3>
                        </div>

                        <div class="col-md-5 col-sm-6 col-xs-12">
                            <div class="d-flex justify-content-end mt-3">
                                <i class="fa fa-close text-white pr-5 "  style="cursor: pointer;" @click="hideModal"></i>
                            </div>

                        </div>
                    </div>
                </div>
            </template>
            <b-container fluid>
                <div >
                    <!--Files upload starts here-->
                    <div class=" p-2">
                        <!--                            <form ref="formData" class="form" role="form" autocomplete="off" @submit.prevent="">-->
                        <b-card>
                            <b-row>
                                <b-col class="bg-dark text-white p-2"  >Campaign Settings: </b-col>

                            </b-row>
                            <!--                                            search box-->
                            <b-row>
                                <b-col class="text-dark" style="background-color:#dee2e6 ">

                                    <b-form-group  size="sm" label="Upload Email Creative"
                                                   label-for="form-email" label-cols-lg="2"
                                                   class="pt-3">
                                        <b-input-group size="sm"  >
                                            <b-form-input v-model="creative.fileName"
                                                          style="border-radius: 0px !important;"
                                                          :disabled="busy"></b-form-input>
                                            <b-input-group-append>
                                                <b-button ref="button"
                                                          style="border-radius: 0px !important;"
                                                          :disabled="busy" variant="dark"
                                                          v-b-modal.modalPopover>
                                                    Upload
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>

                            </b-row>
                            <!--Start time-->
                            <b-row class="container-fluid mt-3">
                                <b-col>
                                    <b-row>
                                        <b-col class="bg-light pt-4" >
                                            <b-row>
                                                <b-col cols="4" class="pt-1">
                                                    <label class="">Start Date</label>
                                                </b-col>
                                                <b-col cols="7" >

                                                    <b-input-group class="mb-3 " size="sm">
                                                        <b-form-input
                                                            id="example-input"
                                                            v-model="creative.StartDate"
                                                            type="text"
                                                            placeholder="YYYY-MM-DD"
                                                            autocomplete="off"
                                                        ></b-form-input>
                                                        <b-input-group-append >
                                                            <b-form-datepicker
                                                                v-model="creative.StartDate"
                                                                button-only
                                                                right
                                                                locale="en-US"
                                                                aria-controls="example-input"
                                                                @context="onContext"
                                                                size="sm"
                                                            ></b-form-datepicker>
                                                        </b-input-group-append>
                                                    </b-input-group>




                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col class="bg-light pt-3 ml-2" cols="5" >
                                            <b-row>
                                                <b-col cols="5" class="pt-1">
                                                    <label>Start Time</label>
                                                </b-col>
                                                <b-col  cols="7">
                                                    <b-input-group size="sm" class="mr-5">
                                                        <b-form-input  v-model="creative.StartTime" v-mask="'##:##'"
                                                                       type="text" size="sm" autocomplete="off"
                                                                       placeholder="00:00"></b-form-input>
                                                    </b-input-group>
                                                </b-col>
                                            </b-row>

                                        </b-col>
                                    </b-row>

                                    <!--                                                    stop date and time-->
                                    <b-row class="mt-2">
                                        <b-col class="bg-light pt-4" >
                                            <b-row>
                                                <b-col cols="4" class="pt-1">
                                                    <label class="">Stop Date</label>
                                                </b-col>
                                                <b-col cols="7" >
                                                    <b-input-group class="mb-3 " size="sm"
                                                                   label="Upload Email Creative">
                                                        <b-form-input
                                                            id="example-input"
                                                            v-model="creative.StopDate"
                                                            type="text"
                                                            placeholder="YYYY-MM-DD"
                                                            autocomplete="off"
                                                        ></b-form-input>
                                                        <b-input-group-append >
                                                            <b-form-datepicker
                                                                v-model="creative.StopDate"
                                                                button-only
                                                                right
                                                                locale="en-US"
                                                                aria-controls="example-input"
                                                                @context="onContext"
                                                                size="sm"
                                                            ></b-form-datepicker>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col class="bg-light pt-3 ml-2" cols="5" >
                                            <b-row>
                                                <b-col cols="5" class="pt-1">
                                                    <label>Stop Time</label>
                                                </b-col>
                                                <b-col  cols="7">
                                                    <b-input-group size="sm" class="mr-5">
                                                        <b-form-input v-model="creative.StopTime" v-mask="'##:##'"
                                                                      type="text" size="sm" autocomplete="off"
                                                                      placeholder="00:00"
                                                        ></b-form-input>
                                                    </b-input-group>
                                                </b-col>
                                            </b-row>

                                        </b-col>
                                    </b-row>

                                </b-col>
                                <!--Dail click-->
                                <b-col  class="bg-light pt-2 ml-2 " cols="5">
                                    <h6> Daily Click Distribution Calculation</h6>

                                    <b-form-group
                                        v-slot="{ ariaDescribedby }"
                                        class="p-1"
                                    >
                                        <b-form-checkbox-group
                                            v-model="creative.ClickDistributionCalculation"
                                            :options="options"
                                            value-field="code"
                                            text-field="name"
                                            :aria-describedby="ariaDescribedby"
                                            name="flavour-2a"
                                            :multiple="false"
                                            stacked
                                        ></b-form-checkbox-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <!--time on site-->
                            <b-row class=" mt-3">
                                <b-col class="bg-light pt-2" cols="4">
                                    <b-row>
                                        <b-col cols="5" class="pt-1">
                                            <label>No. of Clicks</label>
                                        </b-col>
                                        <b-col cols="7">
                                            <b-input-group size="sm" >
                                                <b-form-input type="number" v-model="creative.NumberOfClicks"
                                                              :max="domains[0].NumberOfClicks"
                                                ></b-form-input>
                                            </b-input-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col class="bg-light pt-3 ml-2" cols="7">
                                    <b-row>
                                        <b-col cols="4" class="pt-1">
                                            <label>Above the fold</label>
                                        </b-col>
                                        <b-col cols="3" class="pt-1">
                                            <output :value="creative.above" class="range-slider-tooltip ml-1">{{ creative.above * 100 }}%</output>
                                        </b-col>
                                        <b-col cols="5">
                                            <b-input-group size="sm" class="">
                                                <b-form-input v-model="creative.above" @change="$forceUpdate" type="range" step=".1" min="0" max="1"></b-form-input>
                                            </b-input-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row class=" mt-3">
                                <b-col class="bg-light pt-2" cols="4">
                                    <b-row>
                                        <b-col cols="10" class="pt-1">
                                            <label>Use google analytics tracking</label>
                                        </b-col>
                                        <b-col cols="2">
                                            <b-input-group size="sm" >
                                                <b-form-checkbox :value="true"  :unchecked-value="false" v-model="creative.googleanalytics.isRequired" name="check-button" switch/>
                                            </b-input-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col v-if="creative.googleanalytics.isRequired" class="bg-light pt-2 ml-2" cols="7">
                                    <b-row>
                                        <b-col cols="5" class="pt-1">
                                            <label>Google Analytics Source</label>
                                        </b-col>
                                        <b-col cols="7">
                                            <b-input-group size="sm" >
                                                <b-form-input @keypress="limitSpecial($event)" v-model="creative.googleanalytics.source"></b-form-input>
                                            </b-input-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <!-- multiselect -->
                              <b-row class=" mt-3">
                                  <b-col  class="bg-light pt-2 ml-2 " cols="4">
                                    <b-row>
                                        <b-col cols="4" class="pt-1">
                                            <label>Wait Until </label>
                                        </b-col>
                                        <b-col cols="8">
                                            <select v-model="creative.waitUntil" class="form-control">
                                                <option v-for="wait in waituntil" :key="wait.value" :value="wait.value">{{wait.text}}</option>
                                        </select>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col class="bg-light pt-2 ml-2" cols="7">
                                    <b-row>
                                        <b-col cols="3" class="pt-1">
                                            <label>Behaviors </label>
                                        </b-col>
                                        <b-col cols="9">
                                             <div class="col-12 col-md-12 pl-4 pr-4" >
                                                <ejs-multiselect id='multiselectcity' v-model="creative.behaviors" :fields="behaviorFields" :allowFiltering="true" placeholder="Select Behaviors" :dataSource='behavior'></ejs-multiselect>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <!-- <b-col  class="bg-light pt-2 ml-2" cols="3">
                                    <b-row>
                                        <b-col cols="7" class="pt-1">
                                            <label>Navigation Timeout</label>
                                        </b-col>
                                        <b-col cols="5">
                                        <b-input-group size="sm" >
                                                <b-form-input type="number" v-model="creative.navigationTimeout"
                                                              :max="100"
                                                ></b-form-input>
                                            </b-input-group>
                                        </b-col>
                                    </b-row>
                                </b-col> -->
                                
                            </b-row>
                              <b-row class=" mt-3">
                                  <b-col  class="bg-light pt-2 ml-2 " cols="4">
                                    <b-row>
                                        <b-col cols="4" class="pt-1">
                                            <label>Navigation Timeout </label>
                                        </b-col>
                                        <b-col cols="8">
                                              <b-input-group  >
                                                <b-form-input type="number" v-model="creative.navigationTimeout"
                                                              :max="100"
                                                ></b-form-input>
                                            </b-input-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col class="bg-light pt-2 ml-2" cols="7">
                                    <b-row>
                                        <b-col cols="3" class="pt-1">
                                            <label>Provider </label>
                                        </b-col>
                                        <b-col cols="9">
                                             <div class="col-12 col-md-12 pl-4 pr-4" >
                                                <ejs-multiselect id='multiselectcity' v-model="creative.provider" :fields="providerFields" :allowFiltering="true" placeholder="Select Provider" :dataSource='provider'></ejs-multiselect>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <!-- <b-col  class="bg-light pt-2 ml-2" cols="3">
                                    <b-row>
                                        <b-col cols="7" class="pt-1">
                                            <label>Navigation Timeout</label>
                                        </b-col>
                                        <b-col cols="5">
                                        <b-input-group size="sm" >
                                                <b-form-input type="number" v-model="creative.navigationTimeout"
                                                              :max="100"
                                                ></b-form-input>
                                            </b-input-group>
                                        </b-col>
                                    </b-row>
                                </b-col> -->
                                
                            </b-row>
                           <!--  -->
                           <!-- tags -->
                             <b-row class=" mt-3">
                                  <b-col  class="bg-light pt-2 ml-2 " cols="4">
                                    <b-row>
                                        <b-col cols="4" class="pt-1">
                                            <label>Max Proxy Distance</label>
                                        </b-col>
                                        <b-col cols="8">
                                              <b-input-group  >
                                                <b-form-input type="number" v-model="creative.maxProxyDistance"
                                                              :max="100"
                                                ></b-form-input>
                                            </b-input-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col class="bg-light pt-2 ml-2" cols="7">
                                    <b-row>
                                        <b-col cols="3" class="pt-1">
                                            <label>Blacklist  </label>
                                        </b-col>
                                        <b-col cols="9">
                                             <div class="col-12 col-md-12 pl-4 pr-4" >
                                                <b-form-tags input-id="tags-basic" v-model="creative.blacklist" placeholder="Enter Blacklist here"></b-form-tags>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                              
                                
                            </b-row>
                           <!-- tags -->
                            <!-- Yes and No -->
                               <b-row class=" mt-3">
                                <b-col class="bg-light pt-2" cols="4">
                                    <b-row>
                                        <b-col cols="6" class="pt-1">
                                            <label>Local Links Only</label>
                                        </b-col>
                                        <b-col cols="6">
                                        <select v-model="creative.localLinksOnly" class="form-control">
                                            <option :value="'yes'" selected>Yes</option>
                                            <option :value="'no'">No</option>
                                        </select>
                                        
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col  class="bg-light pt-2 ml-2" cols="4">
                                    <b-row>
                                        <b-col cols="7" class="pt-1">
                                            <label>Local Links Navigation Alternative</label>
                                        </b-col>
                                        <b-col cols="5">
                                            <select v-model="creative.localLinksNavigationAlternative" class="form-control">
                                            <option :value="'yes'" selected>Yes</option>
                                            <option :value="'no'">No</option>
                                        </select>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col  class="bg-light pt-2 ml-2 " cols="3">
                                    <b-row>
                                        <b-col cols="4" class="pt-1">
                                            <label>Bounce</label>
                                        </b-col>
                                        <b-col cols="8">
                                            <select v-model="creative.bounce" class="form-control">
                                            <option :value="'yes'" selected>Yes</option>
                                            <option :value="'no'">No</option>
                                        </select>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <!--  -->
                             <b-row class=" mt-3">
                                <b-col class="bg-light pt-2" cols="4">
                                    <b-row>
                                        <b-col cols="4" class="pt-1">
                                            <label>Mobile </label>
                                        </b-col>
                                        <b-col cols="8">
                                        <select v-model="creative.mobile" class="form-control">
                                            <option :value="'yes'" selected>Yes</option>
                                            <option :value="'no'">No</option>
                                        </select>
                                        
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col  class="bg-light pt-2 ml-2" cols="4">
                                    <b-row>
                                        <b-col cols="4" class="pt-1">
                                            <label>Strict </label>
                                        </b-col>
                                        <b-col cols="8">
                                            <select v-model="creative.strict" class="form-control">
                                            <option :value="'yes'" selected>Yes</option>
                                            <option :value="'no'">No</option>
                                        </select>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col  class="bg-light pt-2 ml-2 " cols="3">
                                    <b-row>
                                        <b-col cols="4" class="pt-1">
                                            <label>Use Proxy</label>
                                        </b-col>
                                        <b-col cols="8">
                                            <select v-model="creative.useProxy" class="form-control">
                                            <option :value="'yes'" selected>Yes</option>
                                            <option :value="'no'">No</option>
                                        </select>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <!--  -->
                              <b-row class=" mt-3">
                                <b-col class="bg-light pt-2" cols="4">
                                    <b-row>
                                        <b-col cols="4" class="pt-1">
                                            <label>Load Images </label>
                                        </b-col>
                                        <b-col cols="8">
                                        <select v-model="creative.loadImages" class="form-control">
                                            <option :value="'yes'" selected>Yes</option>
                                            <option :value="'no'">No</option>
                                        </select>
                                        
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col  class="bg-light pt-2 ml-2" cols="4">
                                    <b-row>
                                        <b-col cols="4" class="pt-1">
                                            <label>Hot Jar  </label>
                                        </b-col>
                                        <b-col cols="8">
                                            <select v-model="creative.hotJar" class="form-control">
                                            <option :value="'yes'" selected>Yes</option>
                                            <option :value="'no'">No</option>
                                        </select>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col  class="bg-light pt-2 ml-2 " cols="3">
                                    <b-row>
                                        <b-col cols="4" class="pt-1">
                                            <label>FQ Tag</label>
                                        </b-col>
                                        <b-col cols="8">
                                            <select v-model="creative.fqTag" class="form-control">
                                            <option :value="'yes'" selected>Yes</option>
                                            <option :value="'no'">No</option>
                                        </select>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                             <b-row class=" mt-3">
                                <b-col class="bg-light pt-2" cols="4">
                                    <b-row>
                                        <b-col cols="4" class="pt-1">
                                            <label>Crawl Throttling </label>
                                        </b-col>
                                        <b-col cols="8">
                                        <select v-model="creative.crawlThrottling" class="form-control">
                                            <option :value="'yes'" selected>Yes</option>
                                            <option :value="'no'">No</option>
                                        </select>
                                        
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col  class="bg-light pt-2 ml-2" cols="4">
                                    <b-row>
                                        <b-col cols="4" class="pt-1">
                                            <label>Crawl Threshold  </label>
                                        </b-col>
                                        <b-col cols="8">
                                            <b-input-group  >
                                                <b-form-input type="number" v-model="creative.crawlThreshold"
                                                              :max="100"
                                                ></b-form-input>
                                            </b-input-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col  class="bg-light pt-2 ml-2 " cols="3">
                                    <b-row>
                                        <b-col cols="4" class="pt-1">
                                            <label>Max Daily Clicks</label>
                                        </b-col>
                                        <b-col cols="8">
                                            <b-input-group  >
                                                <b-form-input type="number" v-model="creative.maxDailyClicks"
                                                              :max="100"
                                                ></b-form-input>
                                            </b-input-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                             <!-- end of yes and no -->

                            
                            <b-row>
                                <b-col class="text-center mt-4 ">
                                    <b-button-group>
                                        <b-button variant="primary" class="font-weight-bold" type="submit"
                                                  @click="saveDomainConfig"
                                                  :disabled="disabled"
                                                  style="border-radius: 0px !important;"> Submit</b-button>
                                        <b-button v-if="creative._id" variant="info ml-3" size="sm" class="font-weight-bold"
                                                   @click="openMod" >
                                                   Configure TOS </b-button>

                                        <b-button v-if="creative._id && creative.linkLevelTos !='' " variant="dark ml-3 " class="font-weight-bold"
                                                  style="border-radius: 0px !important;" @click="calendar(creative)"> View Calender</b-button>
                                    </b-button-group>
                                </b-col>

                            </b-row>

                        </b-card>
                        <!--                            </form>-->
                        <!--                            form end-->


                        <b-container v-if="plans.PlanListing" class="pt-3">
                            <b-row>
                                <b-col  style="background-color:#dee2e6 ">
                                    <p class="pt-2">Proxy Type: {{plans.PlanListing.ProxyType}}</p>
                                </b-col>
                            </b-row>
                        </b-container>

                        <!--GOES .....right here -->

                        <div class="row d-flex justify-content-center align-content-center pt-3">
                            <!--            Right Section-->
                            <div class="col-12">
                                <b-card no-body>
                                    <div class="card" >
                                        <div  v-for="(dom, idx) in domainsValue" :key="dom._id">
                                            <div id="Residentialcity" v-show="plans.PlanListing.ProxyType === 'ResidentialCity' && dom._id " >
                                                <div class="row mt-4">
                                                    <div class="col-4 pl-4 pr-4">
                                                        <select v-model="locate.country" @change="Getstates();" class="form-control">
                                                            <option value="" selected>-- Any country --</option>
                                                            <option v-for="country in countries" :key="country._id" :value="country.code">{{country.name}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-4 pl-4 pr-4">
                                                        <select v-model="locate.state" @change="Getcities();" class="form-control">
                                                            <option value="" selected>-- Any state --</option>
                                                            <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row mt-4" v-if="locate.state">
                                                    <div class="col-11 col-md-11 pl-4 pr-4" >
                                                        <ejs-multiselect id='multiselectcity' v-model="locate.city" :fields="cityFields" :allowFiltering="true" placeholder="Select Cities" :dataSource='cities'></ejs-multiselect>
                                                    </div>
                                                    <div class="col-1 col-md-1 pl-0 d-flex justify-content-center" v-if="locate.city !== ''">
                                                        <b-button size="sm" variant="success" @click="addGeos(idx, dom._id)" v-text="'Add'" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="Residentialstate" v-if="plans.PlanListing.ProxyType === 'ResidentialState' && dom._id">
                                                <div class="row mt-4">
                                                    <div class="col-4 pl-4 pr-4">
                                                        <select v-model="locate.country" @change="Getstates();"  class="form-control">
                                                            <option value="" selected>-- Any country --</option>
                                                            <option v-for="country in countries" :key="country._id" :value="country.code">{{country.name}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row mt-4" v-if="locate.country">
                                                    <div class="col-11 col-md-11 pl-4 pr-4" >
                                                        <ejs-multiselect id='multiselect' v-model="locate.state" :fields="stateFields" :allowFiltering="true" placeholder="Select Cities" :dataSource='states'></ejs-multiselect>
                                                    </div>
                                                    <div class="col-1 pl-0 d-flex justify-content-center" v-if="locate.state.length !== 0">
                                                        <b-button size="sm" variant="success" @click="addGeos(idx, dom._id)" v-text="'Add'" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="DataCenter" v-if="plans.PlanListing.ProxyType === 'Data Center' && dom._id ">
                                                <div class="row mt-4">
                                                    <div class="col-11 col-md-11 pl-4 pr-4" >
                                                        <ejs-multiselect id='multiselect' v-model="locate.country" :fields="dataFields" :allowFiltering="true" placeholder="Select Countries" :dataSource='countries'></ejs-multiselect>
                                                    </div>

                                                    <div class="col-1 col-md-1 pl-0 d-flex justify-content-center" v-if="locate.country.length !== 0">
                                                        <b-button size="sm" variant="success" @click="addGeos(idx, dom._id)" v-text="'Add'" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="Mobile" v-if="plans.PlanListing.ProxyType === 'Mobile' && dom._id ">
                                                <div class="row mt-4">
                                                    <div class="col-4 pl-4 pr-4">
                                                        <select v-model="locate.country" @change="Getstates();" class="form-control">
                                                            <option value="" selected>-- Any country --</option>
                                                            <option v-for="country in countries" :key="country._id" :value="country.code">{{country.name}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-4 pl-4 pr-4">
                                                        <select v-model="locate.state" @change="Getcities();" class="form-control">
                                                            <option value="" selected>-- Any state --</option>
                                                            <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row mt-4" v-if="locate.state">
                                                    <div class="col-11 col-md-11 pl-4 pr-4" >
                                                        <ejs-multiselect id='multiselect' v-model="locate.city" :fields="mobileFields" :allowFiltering="true" placeholder="Select Cities" :dataSource='cities'></ejs-multiselect>
                                                    </div>
                                                    <div class="col-1 col-md-1 pl-0 d-flex justify-content-center">
                                                        <b-button size="sm" variant="success" @click="addGeos(idx, dom._id)" v-text="'Add'" />
                                                    </div>
                                                </div>
                                                <hr>
                                                <b-table
                                                    striped
                                                    :fields="fields"
                                                    ref="selectedRow"
                                                    :items="dom.geos">

                                                    <template v-slot:cell(city)="row">
                                                        <citySelect :domid="dom._id" :id="row.item._id" :value="row.item.city" :index="row.index" :planIdx="idx" :country="row.item.country" v-on:send-city="getcity" :state="row.item.state"></citySelect>
                                                    </template>

                                                    <template v-slot:cell(Actions)="row" >
                                                            <b-button @click="removeGeos(row.index, idx, dom._id, row.item._id)" v-b-tooltip.hover title="Delete Geo"  variant="danger" size="sm"><span class="fa fa-trash"></span></b-button>
                                                    </template>

                                                </b-table>
                                            </div>
                                            <div class="row pr-3 pl-3 mt-2" v-if="plans.PlanListing.ProxyType === 'ResidentialCity'  && dom._id ">
                                                <b-table
                                                    striped
                                                    :fields="fields"
                                                    ref="selectedRow"
                                                    :items="dom.geos">

                                                    <template v-slot:cell(city)="row">
                                                        <citySelect :value="row.item.city" :disable="dom.status === 'active'" :domid="dom._id" :id="row.item._id" :index="row.index" :planIdx="idx" :country="row.item.country" v-on:send-city="getcity" :state="row.item.state"></citySelect>
                                                    </template>

                                                    <template v-slot:cell(Actions)="row" >
                                                        <b-button @click="removeGeos(row.index, idx, dom._id, row.item._id)" v-b-tooltip.hover title="Delete Geo"  variant="danger" size="sm"><span class="fa fa-trash"></span></b-button>
                                                    </template>

                                                </b-table>
                                            </div>
                                            <div class="row pr-3 pl-3 mt-2" v-if="plans.PlanListing.ProxyType  === 'ResidentialState' && dom._id ">
                                                <b-table
                                                    striped
                                                    :fields="RSfields"
                                                    ref="selectedRow"
                                                    :items="dom.geos">

                                                    <template v-slot:cell(state)="row">
                                                        <stateSelect :domid="dom._id" :id="row.item._id" :index="row.index" :planIdx="idx" :country="row.item.country" v-on:send-state="getstate" :state="row.item.state"></stateSelect>
                                                    </template>

                                                    <template v-slot:cell(Actions)="row" >
                                                        <b-button @click="removeGeos(row.index, idx, dom._id, row.item._id)" v-b-tooltip.hover title="Delete Geo" variant="danger" size="sm"><span class="fa fa-trash"></span></b-button>
                                                    </template>

                                                </b-table>
                                            </div>
                                            <div class="row pr-3 pl-3 mt-2" v-if="plans.PlanListing.ProxyType === 'Data Center' && dom._id">
                                                <b-table
                                                    striped
                                                    :fields="datFields"
                                                    ref="selectedRow"
                                                    :items="dom.geos">
                                                    <template v-slot:cell(country)="row">
                                                        <countrySelect :domid="dom._id" :id="row.item._id"  :value="row.item.country" :planIdx="idx" :index="row.index" v-on:send-country="getcountry"></countrySelect>
                                                    </template>

                                                    <template v-slot:cell(Actions)="row" >
                                                        <b-button @click="removeGeos(row.index, idx, dom._id, row.item._id)" v-b-tooltip.hover title="Delete Geo" variant="danger" size="sm"><span class="fa fa-trash"></span></b-button>
                                                    </template>

                                                </b-table>
                                            </div>

                                            <configure-geo v-if="configureGeo" :domain="dom" :domain_nm="domainsNM" :plan="plans" :noc="Number(dom.NumberOfClicks)" :nocd="Number(dom.NumberOfClicksDelivered)" :geos="geoConfig" v-on:closegeos="configureGeo = false" />

                                            <div class="row p-1 d-flex justify-content-center" v-if="dom.geos && dom.geos.length !== 0 ">
                                                <b-button size="sm" @click="configGeoOpen()"  variant="info">Configure Geos</b-button>
                                            </div>

                                            <div class="row p-1 d-flex justify-content-center">
                                                <div class="col-12 col-md-12 d-flex justify-content-center">

                                                    <b-button class="" size="sm" @click="saveDomainConfig" v-if="dom.status === 'active'" ><strong> Edit Campaign</strong></b-button>
                                                     <b-button class="" size="sm" @click="generateCampaign(dom)" v-else ><strong> Start Campaign</strong></b-button>
                                                </div>
                                            </div>
                                            <div class="row p-1 d-flex justify-content-center" v-if="dom.status === 'active'">
                                                <div class="col-12 col-md-12 d-flex justify-content-center">

                                                    <b-button variant="dark " size="sm" class="font-weight-bold"
                                                              to="/clients" @click="$router.push({ path: '/resultReporting', query: { dom_id: dom._id, product_type: dom.product_type } })">
                                                        Client Report </b-button>
                                                </div>
                                            </div>
                                            <b-alert v-if="dom.status === 'active'" variant="success" show>Campaign  Started </b-alert>
                                        </div>

                                    </div>
                                </b-card>
                            </div>
                            <!--            End Right Section-->
                        </div>

                        <!--ends here-->
                    </div>


                </div>
            </b-container>

            <template #modal-footer>
                <div class="w-100">



                    <b-button variant="dark" class="font-weight-bold"
                              @click="hideModal"
                              style="border-radius: 0px !important; background-color: black">BACK</b-button>

                </div>
            </template>
        </b-modal>

        <t-o-s v-if="tos" :linklvlTOS="creative.linkLevelTos"  :domain_config_id="creative._id" v-on:close="closeMod" v-on:modified="tosNotify()"></t-o-s>
        <b-modal id="modalPopover"  hide-header hide-footer     ref="modalPopover"  >

            <b-container >
                <h6 class="text-center p-2">
                    Upload Creative
                </h6>
                <p>Select and Upload email file:</p>
                <div id="dropArea">
                    <input type="file" accept=".html" ref="uploadObj" @change="fileupload"/>
                </div>
                <b-button class="text-danger float-right mt-3" @click="hideUploadModal" variant="outline-light">Done</b-button>
            </b-container>


        </b-modal>




        <!-- Large modal -->
<!--        <button type="button" class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Large modal</button>-->



        <!--        time on site-->

        <footerDesign  first-button="Add a Product" first-button-show="false"  second-button="Product Configuration"
                      :second-button-link="{ path: '/product/management', query: { client_id: plans.ClientId } }"
                       second-button-show="false" third-button-show="false" third-button="Back To Clients" :third-button-link="{ path: '/clients'}"
                       class="mt-4">

        </footerDesign>
    </div>
</template>



<script>
import footerDesign from "@/components/style_Component/footerDesign";
import headerDesign from "@/components/style_Component/headerDesign";
import citySelect from "../components/domain/citySelect";
import { UploaderPlugin } from '@syncfusion/ej2-vue-inputs';
import { MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";
import Vue from "vue";
import axios from "axios";
import swal from "sweetalert";
import TOS from "@/components/domain/configuration/tos";
import {mapMutations} from "vuex";
import stateSelect from "@/components/domain/stateSelect";
import countrySelect from "@/components/domain/countrySelect";
import ConfigureGeo from "@/components/domain/configuration/geos";

Vue.use(UploaderPlugin);
Vue.use(MultiSelectPlugin);

export default {
    name: "creativeManagement",
    components: {
        footerDesign,
        headerDesign,
        citySelect,
        stateSelect,
        countrySelect,
        ConfigureGeo,
        TOS
    },
    data(){
        return{
            response:['yes','no'],
            plans: {},
            proxyInfo:false,
            tos:false,
            showit: true,
            domains: [],
            Refresh: false,
            currentEndTime: '',
            ga:{
                isrequired: false,
                source: "",
                unqiue:""
            },
            domainsValue: [],
            domainsNM: [],
            configureGeo: false,
            configureTos: false,
            itemSeletected: '',
            indexSelected:'',
            filevalue:false,
            stopDate: '',
            client:'',
            domain: {
                domain: '',
                NumberOfClicks: '',
                TimeOnSite: '',
                StartTime: '',
                StopTime: '',
                StartDate: '',
                StopDate: '',
                LinkLevel: 0,
                geos: [],
            },

            creative:{
                StartDate:'',
                StopDate:'',
                StartTime:'',
                StopTime:'',
                NumberOfClicks: 0,
                LinkLevel:'',
                creative:'',
                above: 0.7,
                ClickDistributionCalculation:'',
                fileName:'',
                googleanalytics: {
                    source:"",
                    unqiue:"",
                    isRequired: false
                },
                localLinksOnly:'',
                localLinksNavigationAlternative: '',
                bounce:'',
                behavior:'',
                navigationTimeout:'',
                waitUntil:'',
                mobile:'',
                strict:'',
                maxProxyDistance:'',
                useProxy:'',
                provider:'',
                loadImages:'',
                hotJar:'',
                fqTag:'',
                crawlThreshold:'',
                crawlThrottling:'',
                maxDailyClicks:'',
                blacklist:'',
            },

            countries: [],
            states: [],
            cities: [],
            edit: false,
            click: false,
            tableEdit: false,
            tableindex: '',
            locate: {
                country: '',
                state: '',
                city: '',
            },
            providerFields:{text: 'text', value: 'value'},
            behaviorFields:{text: 'text', value: 'value'},
            countryFields:{ text: 'country_name', value: 'country_name' },
            cityFields: { text: 'city_name', value: 'city_name' },
            stateFields: { text: 'city_name', value: 'state_name' },
            dataFields: { text: 'name', value: 'code' },
            mobileFields: { text: 'city_name', value: 'city_name' },


            field: ['Creative_Name', 'Creative_ID', 'Creative_File', 'Date_Added', 'Date_Updated', 'Start_Date',
                'End_Date','Start_Time','End_Time', 'No_of_Clicks_Requested',
                'No_of_Clicks_Delivered','Percentage_of_Clicks_Delivered', 'No_of_Geos',
                'Daily_Click_Distribution_Calculation',
                'Status'
            ],
            item: [],
            behavior:'',
            provider:'',
            waituntil:'',
            configureGeoShow:false,
            modes: ['multi', 'single', 'range'],
            selectMode: 'single',
            selected: [],
            button:'',
            endTime:'',
            showModal:false,
            options:[{name: 'Fast', code: 'fast'}, {name: 'Slow', code: 'slow'},{name: 'Uniform', code: 'uniform'}],
            show: false,
            variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'],
            headerBgVariant: 'dark',
            headerTextVariant: 'light',
            bodyBgVariant: 'light',
            bodyTextVariant: 'dark',
            footerBgVariant: 'dark',
            footerTextVariant: 'dark',
            filelist: [],
            dropElement: '#drop',
            isBusy: false,
            disabled: false,

            path:  {
                saveUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Save',
                removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove'
            },
            autoUpload: false,

            //starts here
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            }
        }
    },

    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        },
        fields() {
            return [
                {
                    key: 'country',
                    label: 'Country',
                    sortable: false
                },
                {
                    key: 'state',
                    label: 'State',
                    sortable: false
                },
                {
                    key: 'city',
                    label: 'City',
                    sortable: false
                },
                {
                    key: 'Actions',
                    label: 'Actions',
                    sortable: false
                }
            ]
        },
        RSfields() {
            return [
                {
                    key: 'country',
                    label: 'Country',
                    sortable: false
                },
                {
                    key: 'state',
                    label: 'State',
                    sortable: false
                },
                {
                    key: 'Actions',
                    label: 'Actions',
                    sortable: false
                }
            ]
        },
        datFields(){
            return [
                {
                    key: 'country',
                    label: 'Country',
                    sortable: false
                },
                {
                    key: 'Actions',
                    label: 'Actions',
                    sortable: false
                }
            ]
        },

    },

    created() {
        this.$root.preloader = false;
        this.loadcountries();
        this.behaviors();
        this.getProvider();
        this.waitUntil();
        this.loadPlans().finally(() => {
            this.getClient();
            this.getCreative().finally(() => {
                if ( this.domains.length === 0 ) {
                    this.incrementDomain();
                }
                this.getClientDomains()


            })
        })

    },
    filters: {
        convert: function(value) {
            if (value < 1000000) {
                return value / 1000 + 'K'
            } else if(value >=  1000000000) {
                return value / 1000000000 + 'G'
            } else {
                return value / 1000000 + 'M'
            }
        },
        convertG: function(value) {
            return value / 1000000000 + 'G'
        }
    },

    methods: {
        ...mapMutations(['setDomain']),

        async getClient() {
            let resp = await axios.get(`${this.$root.serverUrl}/admin/clients/${this.plans.ClientId}`)


            if (resp.data && resp.data.error) {
                swal({title: 'Error', text: resp.data.error.message, icon: 'error'})
                this.disabled = false;
            } else {
                this.client = resp.data.data
            }
        },
         async getProvider() {
            let resp = await axios.get(`${this.$root.serverUrl}/admin/domain/dropdown/providers`)
            if (resp.data.data ) {
               this.provider = resp.data.data
    
            } 
        },
          async behaviors() {
            let resp = await axios.get(`${this.$root.serverUrl}/admin/domain/dropdown/behaviours`)
            if (resp.data.data ) {
               this.behavior = resp.data.data
    
            } 
        },
         async waitUntil () {
            let resp = await axios.get(`${this.$root.serverUrl}/admin/domain/dropdown/waituntil`)
            if (resp.data.data ) {
               this.waituntil = resp.data.data
            } 
        },
        limitSpecial(e){
            if(!e.key.match(/^[a-zA-Z0-9]*$/))
            {
                e.preventDefault();
            }
        },
        saveDomainConfig:async function() {

            try{

                this.creative.plan_id = this.plans.PlanId;
                this.creative.client_id =  this.plans.ClientId;
                this.creative.product_id =  this.plans._id;
                this.creative.NumberOfClicks = Number(this.creative.NumberOfClicks)
                this.creative.product_type ='emailverifier';
                this.creative.timezone = this.client.TimeZone;
                this.creative.geos = this.geoConfig

                    swal({
                        title: "Loading",
                        text: "Sending Your Request Please Wait.",
                        icon: "info",
                        showConfirmButton: false,
                    });



                    if(this.creative.status == 'active') {

                        let confirm = "A campaign is currently running for this configuration. Do you still want to edit this file?"

                        swal({
                            title: 'Are you sure?',
                            text: confirm,
                            icon: 'warning',
                            buttons:true,
                            dangerMode:true
                        }).then(async (edit) => {
                            if(edit) {
                                let time = this.currentEndTime.split(':')
                                let hour = parseInt(time[0]) + 1


                                if(hour >= 24) hour = '00'

                                let resp = await axios.post(`${this.$root.serverUrl}/admin/domain`,{params:{domains:this.creative }})


                                if (resp.data && resp.data.error) {
                                    swal({title: 'Error', text: resp.data.error.message, icon: 'error'})
                                    this.disabled = false;
                                } else {
                                    let message = `The campaign for this creative is currently running. Creative changes will take effect at the start of next hour`

                                    swal({
                                        title: 'Success',
                                        text: message,
                                        icon: 'success'
                                    }).then(() => {
                                        this.disabled = false;
                                        location.reload()
                                    })
                                }
                            }
                        })

                    } else {

                        this.disabled = true;
                        let method = (this.creative._id) ? 'post' : 'put';
                        let resp = await axios[method](`${this.$root.serverUrl}/admin/domain`, {params: {domains: this.creative}})

                        if (resp.data && resp.data.error) {
                            swal({title: 'Error', text: resp.data.error.message, icon: 'error'})
                            this.disabled = false;
                        } else {
                            swal({
                                title: 'Success',
                                text: 'Email configured successfully.',
                                icon: 'success'
                            }).then(() => {
                                this.disabled = false;
                                location.reload()
                            })
                        }
                    }

            }catch (e) {
                console.log(e)

            }

        },
        getstate(item, idx, planIdx) {
            this.domains[planIdx].geos.splice(idx, 1, item)
            this.tableindex = idx

            axios.post(`${this.$root.serverUrl}/admin/domain/geos`, item).then((resp) => {
                if ( resp.data ) {
                    this.$bvToast.toast('Geos updated successfully.', {
                        title: <strong>Information: Geos Updated</strong>,
                        variant: 'info',
                        solid: true
                    })
                }
            })
        },
        openMod(){
            this.tos = true;
            this.$refs["modal"].hide();
        },
        closeMod(){
            // this.$refs["modal"].show();
            this.onRowSelected(this.itemSeletected, this.indexSelected)
            this.tos = false;
        },
        getcountry(item, idx, planIdx) {
            this.domains[planIdx].geos.splice(idx, 1, item)
            this.tableindex = idx

            axios.post(`${this.$root.serverUrl}/admin/domain/geos`, item).then((resp) => {
                if ( resp.data ) {
                    this.$bvToast.toast('Geos updated successfully.', {
                        title: <strong>Information: Geos Updated</strong>,
                        variant: 'info',
                        solid: true
                    })
                }
            })
        },
        //gets all the creative info
        getCreative() {
            let request = {'apikey': this.$root.apikey};
            this.isBusy = true


            try {

                this.Refresh = true
                return axios.get(`${this.$root.serverUrl}/admin/domain/product/${this.plans._id}`, {params: request}).then((resp) => {
                    this.isBusy = false
                    let items = resp.data.data
                    this.domains = [...resp.data.data];
                    // this.domains = resp.data.data
                    this.item = [];
                    this.creative.NumberOfClicks = this.plans.PlanListing.NumberOfClicks;

                    if(resp.data.data.length > 0 ) this.Refresh = false

                    for (let data in items) {
                        this.item.push({
                            _id: items[data]._id,
                            Creative_Name: items[data].fileName,
                            Creative_ID: items[data].id,
                            Creative_File: items[data].fileName,
                            creative: items[data].creative,
                            Date_Added: this.getDate(items[data].created_ts),
                            Date_Updated: this.getDate(items[data].modified_ts),
                            plan_id: items[data].plan_id,
                            product_id: items[data].product_id,
                            product_type: items[data].product_type,
                            Start_Date: items[data].StartDate.split('T')[0],
                            End_Date: items[data].StopDate.split('T')[0],
                            Start_Time: items[data].StartTime,
                            End_Time: items[data].StopTime,
                            Time_on_Site: '',
                            linkLevelTos: items[data].linkLevelTos,
                            No_of_Clicks_Requested: items[data].NumberOfClicks,
                            No_of_Clicks_Delivered: '',
                            Percentage_of_Clicks_Delivered: '',
                            No_of_Geos: (items[data].geos) ? items[data].geos.length : 0,
                            Daily_Click_Distribution_Calculation: items[data].click_distribution,
                            Status: items[data].status,
                            Above: items[data].above,
                            googleanalytics: items[data].googleanalytics
                        })
                    }

                    //this is called when the function is called
                    if (this.item[0]._id != undefined && this.indexSelected != '') {
                        this.onRowSelected(this.item[this.indexSelected], this.indexSelected)
                    }



                })
            }catch{
                console.log('')
            }
        },
        onRowSelected(items,index) {

            this.isBusy = false
            this.itemSeletected = items

            console.log('this is selected item')
            console.log(items.No_of_Clicks_Requested)
            this.indexSelected = index
            // if(this.creative._id != undefined){
                this.creative = {
                    StartDate:items.Start_Date,
                    StopDate:items.End_Date,
                    StartTime:items.Start_Time,
                    StopTime:items.End_Time,
                    NumberOfClicks:(items.No_of_Clicks_Requested != undefined) ? items.No_of_Clicks_Requested : 0,
                    LinkLevel:items.Link_Level,
                    ClickDistributionCalculation:items.Daily_Click_Distribution_Calculation,
                    fileName:items.Creative_Name,
                    creative:items.creative,
                    _id:items._id,
                    status:items.Status,
                    linkLevelTos: items.linkLevelTos,
                    above: items.Above,
                    googleanalytics: items.googleanalytics
                }

                this.currentEndTime = items.End_Time
                //  Hacky workaround needs removal.
                if(this.creative.googleanalytics === undefined || this.creative.googleanalytics === 'undefined'|| this.creative.googleanalytics === null)
                {
                    this.creative.googleanalytics = {
                        source:"",
                        unqiue:"Adretreaver",
                        isRequired: false
                    }
                }

            if(this.creative.StartTime == undefined) this.creative.StartTime = '00:00'
            if(this.creative.StopTime == undefined) this.creative.StopTime = '23:59'

            if(this.creative.NumberOfClicks == 0) {
                this.creative.NumberOfClicks = (this.domains[0].NumberOfClicks != 0) ? this.domains[0].NumberOfClicks: this.plans.PlanListing.Bandwidth
            }
            if(this.creative.above == undefined) this.creative.above = 0.7
            if(this.creative.ClickDistributionCalculation == undefined) this.creative.ClickDistributionCalculation = 'fast'

            if(index != undefined){
                this.domainsValue = [this.domains[index]];
                if( this.domains[index].geos && this.domains[index].geos.length > 0 ){
                    this.configGeo(this.domains[index]._id)
                }
            }


            this.selected = items
            this.showModal = true
            this.$refs["modal"].show();
        },

        //Ask Rodney
        generateCampaign(dom) {
            swal({ title: "Processing...", text: "Your request is being processed.", icon:"info" } )

            axios.put(`${this.$root.serverUrl}/admin/domain/campaigns/${dom._id}`).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    this.$bvToast.toast(resp.data.error.message, {
                        title: <strong>Warning: Campaign not generated.</strong>,
                        variant: 'danger',
                        solid: true
                    })
                } else {
                    swal({ title: "Campaign has been generated.", text: "Campaign Generated.", icon:"success" } ).then(() => {
                        // location.reload()
                    })
                }
            })
        },
        configGeo(id) {
        axios.get(`${this.$root.serverUrl}/admin/domain/click-distribution/${id}`).then((resp) => {
            if ( resp.data && resp.data.error ) {
                swal({title: 'Error', text: resp.data.error.message, icon: 'error'})
            } else {
                this.geoConfig = resp.data.data
                this.configureGeoShow = true;
            }
        })
    },
        configGeoOpen(){
            this.configureGeo = true;
        },
        calendar(creative) {
            this.setDomain(creative);
            this.$router.push('/click/schedule')
        },
        tosNotify() {
            this.$bvToast.toast('TOS updated successfully', {
                title: <strong>Information: TOS Updated</strong>,
                variant: 'info',
                solid: true
            })
            this.getCreative()
        },
        fileupload() {

            let file = this.$refs.uploadObj.files[0];
            this.creative.fileName = file.name
            let reader = new FileReader();
            reader.onload = (event) => {
                this.creative.creative = event.target.result;
            };
            reader.readAsText(file);
            // this.$refs["modalPopover"].hide();
        },

        getDate: function (dateTime) {
            let d = new Date(dateTime),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day, year].join('/');
        },
        getTime: function (dateTime) {
            let d = new Date(dateTime);
            return d.toLocaleTimeString('en-US');
        },
        incrementDomain() {
            const total = (this.plans.PlanListing) ? this.plans.PlanListing.NumberOfDomains : 0
            if (this.domains.length !== Number(total)) {
                let object = Object.assign({}, {
                    NumberOfClicks: this.plans.PlanListing.Driven === 'click' ? this.plans.PlanListing.NumberOfClicks : 0,
                    domain_id: '',
                    StartTime: '',
                    StopTime: '',
                    StartDate: '',
                    StopDate: '',
                    geos: [],
                });
                object.plan_id = this.plans.PlanId;
                object.client_id = this.plans.ClientId;
                object.product_id = this.$route.query.plan_id;

                this.domains.push(object)
            }
            // if (this.domains.length === Number(total)) {
            //     this.$bvToast.toast('You have reached the Maximum amount of allotted domains.', {
            //         title: <strong>Information: Maximum domains</strong>,
            //         toaster: 'b-toaster-top-center',
            //         variant: 'info',
            //         solid: true
            //     })
            // }
        },
        getClientDomains() {
            if(this.domains.length > 0){
                axios.get(`${this.$root.serverUrl}/admin/domain/domain/verified/${this.domains[0].client_id}`).then((resp) => {
                    this.domainsNM = resp.data.data || []
                })
            }

        },

        getDomain() {
            let request = {'apikey': this.$root.apikey};
            return axios.get(`${this.$root.serverUrl}/admin/domain/product/${this.$route.query.plan_id}`, {params:request}).then((resp) => {
                this.domains = resp.data.data || []
                if ( this.configure ) {
                    for( let data of this.domains ) {
                        if ( data._id === this.editData._id ) {
                            if(this.data.googleanalytics === null || this.data.googleanalytics === undefined){
                                this.data.googleanalytics = {
                                    isrequired: false,
                                    source: "",
                                    unqiue: ""
                                }
                            }
                            this.domainsValue = data
                            console.log(this.domainsValue)
                        }
                    }
                }
            })
        },
        loadPlans: function () {
            var request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/clients/product/${this.$route.query.plan_id}`, {params: request}).then(function (resp) {
                //Store the stats
                this.plans = (resp.data && resp.data.data) ? resp.data.data : {};
                if ( resp.data && resp.data.data) {
                    this.proxyInfo = true
                }

            }.bind(this));
        },

        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        loadcountries: function () {
            var request = {'apikey': this.$root.apikey, sSearch: ''};

            return axios.get(`${this.$root.serverUrl}/admin/countries`, {params: request}).then(function (resp) {
                //Store the stats
                this.countries = (resp.data && resp.data.data) ? resp.data.data : [];
            }.bind(this));
        },
        hideModal() {



            this.$refs["modal"].hide();
            //if(this.Refresh == true) location.reload()
            //this.getCreative()



        },
        hideUploadModal() {
            this.$bvModal.hide("modalPopover")
        },
        toggle(id) {

            const index = this.opened.indexOf(id);
            if (index > -1) {
                alert('1')
                this.opened.splice(index, 1)
            } else {
                this.opened.push(id)
                alert('5')
            }
        },

        selectThirdRow() {
            // Rows are indexed from 0, so the third row is index 2
            this.$refs.selectableTable.selectRow(2)
        },

        onChange() {
            this.filelist = [...this.$refs.file.files];
        },
        remove(i) {
            this.filelist.splice(i, 1);
        },

        drop(event) {
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.onChange(); // Trigger the onChange event manually
            // Clean up
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-green-300');
        },
        Getstates: function () {
            this.states = [];
            var request = {'apikey': this.$root.apikey, filters: {}};

            if (this.locate.country) request.filters.country_iso_code = this.locate.country;

            return axios.get(`${this.$root.serverUrl}/admin/geos/state`, {params: request}).then(function (resp) {
                //Store the stats
                this.states = (resp.data && resp.data.data) ? resp.data.data : [];
            }.bind(this));
        },
        Getcities: function () {
            this.cities = [];
            var request = {'apikey': this.$root.apikey, filters: {}};

            if (this.locate.country) request.filters.country_iso_code = this.locate.country;
            if (this.locate.state) request.filters.subdivision_1_iso_code = this.locate.state;

            return axios.get(`${this.$root.serverUrl}/admin/geos/city`, {params: request}).then(function (resp) {
                //Store the stats
                this.cities = (resp.data) ? resp.data.data : [];
            }.bind(this));
        },
        getcity(item, idx, planIdx) {
            this.domains[planIdx].geos.splice(idx, 1, item)
            this.tableindex = idx

            axios.post(`${this.$root.serverUrl}/admin/domain/geos`, item).then((resp) => {
                if (resp.data) {
                    this.$bvToast.toast('Geos updated successfully.', {
                        title: <strong>Information: Geos Updated</strong>,
                        variant: 'info',
                        solid: true
                    })
                }
            })
        },
        removeGeos(idx, index, dom, id) {

            swal({title: 'Processing', text: 'Deleting......', icon: 'warning'})
                    axios.delete(`${this.$root.serverUrl}/admin/domain/geos/${dom}/${id}`).then((resp) => {
                        if (!resp.error) {
                            this.domainsValue[index].geos.splice(idx, 1)
                            swal({title: 'Success', text: 'Deleted successfully.', icon: 'success'})
                        } else {
                            this.$bvToast.toast('An Error Occurred.', {
                                title: <strong>ERROR</strong>,
                                variant: 'warning',
                                solid: true
                            })
                        }
                    })

        },
        addGeos(idx, id) {
            this.locate.domainId = id
            swal({
                title: "Loading",
                text: "Sending Your Request Please Wait.",
                icon:"info",
                showConfirmButton: false,
            });
            if(this.domainsValue[idx].geos){
                console.log('')
            }else{
                this.domainsValue[idx].geos =[]
            }


            axios.put(`${this.$root.serverUrl}/admin/domain/geos`, this.locate).then((resp) => {

                if (resp.data) {
                    swal({title: 'Success', text: 'Geo added successfully.', icon: 'success'})
                    this.configGeo(this.domains[idx]._id)

                }

            }).finally(() => {
                this.domainsValue[idx].geos.push(Object.assign({}, this.locate))
                this.locate.country = ''
                this.locate.state = ''
                this.locate.city = ''
                this.$forceUpdate()
            })
        },
    },

}
</script>

<style scoped>

@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";

.range-slider-tooltip {
    margin: 0;
    margin-bottom: 10px;
    padding: 2px 7px;
    background: #122C35;
    border-radius: 5px;
    color: white;
    font-size: 1rem;
    font-weight: 600;
}

#dialog1 {
    z-index: 1999;
}
.modal-body {

    padding: 0rem !important;
}

/*//Small devices (landscape phones, 576px and up)*/
/*@media (min-width: 576px) { ... }*/

/*// Medium devices (tablets, 768px and up)*/
/*@media (min-width: 768px) { ... }*/

/*// Large devices (desktops, 992px and up)*/
/*@media (min-width: 992px) { ... }*/

/*// Extra large devices (large desktops, 1200px and up)*/
/*@media (min-width: 1200px) { ... }*/
</style>
